import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { NgbRatingConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TestServiceService } from "../../../../../services/test_service/test-service.service";
import { Router } from "@angular/router";
import { GlobalService } from "../../../../../services/global/global.service";
import { ListService } from "../../../../../services/list/list.service";
import { MenuService } from "../../../../../services/menu/menu.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiConstData } from "../../../../../../consts/ApiConstData";
import { GlobalFunctions } from "../../../../../../consts/global-functions";
import { ActiveComponentsService } from "../../../../../services/active-components/active-components.service";
import { Configurations } from "../../../../../../consts/configurations";
import { NbToastrService } from "@nebular/theme";
import { Pagination__001_002 } from "../../../_#001__other/_comp__#001_#002__pagination/pagination__001_002.component";
import { SweetAlert } from 'sweetalert/typings/core';
import * as _swal from 'sweetalert';
import * as XLSX from 'xlsx';
import { TranslateService } from "@ngx-translate/core";

const swal: SweetAlert = _swal as any;
@Component({
  selector: "table_cards__014__004_001",
  templateUrl: "./table_cards__014__004_001.component.html",
  styleUrls: ["./table_cards__014__004_001.component.scss"],
})
export class Table_cards__014__004_001 implements OnInit { 
  @ViewChild('pagination',{read:ViewContainerRef}) pagination: ViewContainerRef;
  perPage: number = 4;
  activePage: number = 1;
  arr;
  selectedRow = [];
  constructor(
    ratingConfig: NgbRatingConfig,
    private testService: TestServiceService,
    private router: Router,
    private modal: NgbModal,
    public toastr: NbToastrService,
    private resolver: ComponentFactoryResolver,

    private globalVar: GlobalService,
    private listService: ListService,
    private menuService: MenuService,
    private httpClient: HttpClient,
    private apiConstData: ApiConstData,
    public globalFunction: GlobalFunctions,
    public configration: Configurations,
    private globalVariables: GlobalService,
    private activeComponents: ActiveComponentsService,
    private translateSrevice: TranslateService
  ) {
    ratingConfig.max = 5;
    ratingConfig.readonly = true;
  }
  checkAll = false;
  selected = 3;
  readonly = true;
  tableHeaders;
  check = false;
  selectedItems = [];
  table_data;
  countDrawingPager: number = 0;
  apiLink: string;
  apiParameters;
  moduleID: string;
  isDataAvailable: Boolean = false;
  firstLoad:number = 1;

  ngOnInit() {
    this.getListItem();
    $(".dropdown-menu").click(function (e) {
      e.stopPropagation();
    });
    console.log("api parameteres list Card = >", this.apiParameters);
    // this.table_data = this.testService.newList.content;
    // this.globalVariables.setCompLoadedNum();

    // console.log("table_data => ", this.table_data)
    // console.log("table_data => ", this.table_data.bulk_actions);

    $(document).ready(function () {
      $('input[type="checkbox"].select').click(function () {
        if ($(this).is(":checked")) {
          $(this).parents("tr:first").css("background-color", "#e0e0e0");
        } else if ($(this).is(":not(:checked)")) {
          $(this).parents("tr:first").css("background-color", "white");
        }
      });
      $('input[type="checkbox"].all').click(function () {
        console.log("checl all fired");

        if ($(this).is(":checked")) {
          $('input[type="checkbox"]').attr("check", "true");
          $('input[type="checkbox"].select')
            .parents("tr")
            .css("background-color", "#e0e0e0");
        } else if ($(this).is(":not(:checked)")) {
          $('input[type="checkbox"]').attr("check", "false");

          $('input[type="checkbox"].select')
            .parents("tr")
            .css("background-color", "white");
        }
      });

      $("a.sub-menu").click(function () {
        // $(this).next().css( "display", "block" );
        if ($(this).next().hasClass("--display")) {
          $(this).next().removeClass("--display");
        } else {
          $(this).next().addClass("--display");
        }
      });
    });
  }

  getListItem() {
    console.log("session filter=> ",JSON.parse(sessionStorage.getItem('filter_list')))
    if(sessionStorage.getItem("filter_list")!=null) {
      this.apiLink = JSON.parse(sessionStorage.getItem("filter_list")).api;
      this.apiParameters = JSON.parse(sessionStorage.getItem("filter_list")).parameters;
      sessionStorage.removeItem('filter_list')
    }
    console.log("da5al get list");
    console.log("apiLink list Card = >", this.apiLink);
    let body = {...this.apiParameters,...{pagination:this.perPage,page:this.activePage}}
   console.log("body => ",body)
    this.httpClient
      .post<any>(this.apiLink, body, {
        headers: this.apiConstData.getHeaderandBody().apiHeaders,
      })
      .subscribe(
        (response) => {
          console.log("response table_card=> ", response);
          if(response.status.code == 401){
              localStorage.clear();
              sessionStorage.clear();
              this.router.navigate(['./login'])
          }
          else {
            this.table_data = response.content;
         
            if(this.selectedRow.length > 0){
              this.table_data.basic_data.forEach(element=>{
                console.log("element => ",element)
                this.selectedRow.forEach(item=>{
                  if(element[0].settings.title == 'id'){
                    if(element[0].value == item.id){
                      element['check'] = 'true'
                    }
                  }
                })
              })
              console.log(" this.table_data.basic_data => ", this.table_data.basic_data)
            }
            else {
              this.table_data.basic_data.forEach(element=>{
                element['check'] = 'false'
  
              })
            }
            this.isDataAvailable = true;
            this.globalVariables.setCompLoadedNum();
            console.log(
              "CompLoadedNum_table_card=> ",
              this.globalVariables.compLoadedNum
            );
            console.log("pager => ", response.summary.count.total_rows);
            if (this.countDrawingPager == 0) {
              console.log("countDrawing pager => da5al gwa");
              console.log("total rows=> ",response.summary.count.total_rows);
              console.log("active page=> ",this.activePage)
              if(this.activePage == 1 && this.firstLoad == 1) {
                this.firstLoad +=1;
                this.drawPager(response.summary.count.total_rows, this.activePage);
              }
            }
          }
         
        },
        (error) => {
          this.globalVariables.setCompLoadedNum();
        }
      );
  }

  drawPager(itemNum, activePage): void {
    console.log("pagination (itemNum)", itemNum);
    console.log("pagination (activePage)", activePage);
    var pager;
    const factory = this.resolver.resolveComponentFactory(Pagination__001_002);
    console.log("table_card_factory => ", factory);
    pager = this.pagination.createComponent(factory);
    pager.instance.allItems = itemNum;
    pager.instance.activePage = activePage;
    pager.instance.activePageNum.subscribe((result) => {
      console.log("result pager=> ",result)
      this.activePage = result;
      if(result>1 || (result==1 && this.firstLoad != 1)) {
        console.log("next page page")
       this.table_data = [];
       this.getListItem()
      }
      setTimeout(() => {
        this.globalVariables.setCompLoadedNum();
        console.log("compNumTable=> ", this.globalVariables.compLoadedNum);
      }, 1000);
    });
  }

  getNextPage(page) {
    console.log("next Page=> ",page);
    if(page>1) {
      console.log("next page page")
     this.table_data = [];
     this.getListItem()

    }
  }

  showAction(row_index) {
    console.log("row_index=> ", row_index);
    let show_ref = $(`#actionSection_${row_index}`).attr("show");
    if (show_ref == "false") {
      $(`#actionSection_${row_index}`).attr("show", "true");
      $(`#actionSection_${row_index}`).removeClass("display--none");
      $(`#actionSection_${row_index}`).addClass("display--block");
      // $(`#actionSection_${row_index}`).css("display","block")
    } else {
      $(`#actionSection_${row_index}`).attr("show", "false");
      $(`#actionSection_${row_index}`).removeClass("display--block");
      $(`#actionSection_${row_index}`).addClass("display--none");
    }
  }

  actionFunction(item) {

    console.log("====================================");
    console.log("test actions=>", item);
    console.log("test actions=>", item.target_type);
    console.log("====================================");
    console.log(JSON.parse(localStorage.getItem('user-info')));
    var token = (JSON.parse(localStorage.getItem('user-info'))).api_token;
    if (item.target_type == "external_link") {
      console.log("external_url")
        window.open(`${item.target_url}/${item.parameters.id}?token=${token}`)
    }
    if (item.target_type == "layout") {

      let layoutLink = this.menuService.getLayoutByID(item.target_layout_id);
      
      if( item.target_layout_id == 4){
        console.log("Show Form Condition ::::::::")
        this.router.navigate(['/pages/static-pages/list-show'], {
          queryParams: {
            apiLink: item.target_url,
            apiParameters: JSON.stringify(item.parameters),
          },
        });
      }

      else {
        this.router.navigate([layoutLink], {
          queryParams: {
            apiLink: item.target_url,
            apiParameters: JSON.stringify(item.parameters),
          },
        });
      }
      
      // this.router.navigate(['/pages/layouts/layout-edit'])
    }
    if (item.target_type == "modal") {
      // const activeModal = this.modal.open(LayoutModalComponent, { size: 'lg', container: 'nb-layout' });
      // activeModal.componentInstance.pageID = item.page_id;
    }
    /*Incase of api action like delete*/
    if (item.target_type == "api") {
      console.log("iam here in target type api");
      // console.log("activeComponents=> ",this.activeComponents.getActiveComponents())
      // console.log("item target_url => ",item.target_url)
      // let itemID = item.rowID.value;
      swal({
        title: "",
        text: "هل انت متأكد من حذف هذا العنصر",
        icon: "info",
        dangerMode: true,
      buttons: ["الغاء", "نعم"],
      })
        .then(success => {
          if (success) {
            this.httpClient
        .post(`${apiURL}`, item.parameters, {
          headers: this.apiConstData.getHeaderandBody().apiHeaders,
        })
        .subscribe(
          (res) => {
            console.log("action response=> ",res);
            this.globalFunction.showToastr(
              "success",
              '',
              `${res['status'].message}`,
              this.configration.apiResponseMsg.success.icon
            );
            setTimeout(()=>{
              this.router
              .navigateByUrl("/RefrshComponent", { skipLocationChange: false })
              .then(() =>
                this.router.navigate(["/pages/layouts/layout1"], {
                  queryParams: {
                    componentsList: this.activeComponents.getActiveComponents(),
                  },
                })
              );
            },1000)
            
          },
          (error) => {
            console.log("error => ", error);
            this.globalFunction.showToastr(
              "danger",
              "Error",
              this.configration.apiResponseMsg.danger.msg,
              this.configration.apiResponseMsg.danger.icon
            );
          }
        );
          }
        }
        )
      let apiURL = item.target_url;
      console.log("delete=> ");
     
    }

  }

  checkItem(checkedItem, i) {
    console.log("checked item => ", checkedItem);
    console.log("header=>",this.table_data.header)
    let check_ref = $(`#defaultCheck_${i}`).attr("check");
    let itemID;
    var obj = {}
    checkedItem.forEach((element,index) => {
      if(element.settings.title == 'actions') {
        return;
      }
      else {
        if(element.settings.title == "id"){
          obj[`id`] =  element.value;
        }else {
          obj[`${this.table_data.header[index].label}`] =  element.value;
        }
      }
      if (element.settings.title == "id") {
       
        itemID = element.value;
        console.log("element id =? ", itemID);
      }
    });
    if (check_ref == "false") {
      $(`#defaultCheck_${i}`).attr("check", "true");
      this.selectedRow.push(obj);
      this.selectedItems.push(itemID);
      console.log("fdkvmfk",this.selectedItems);
      console.log("test selected row",this.selectedRow);
    } else {
      $(`#defaultCheck_${i}`).attr("check", "false");
      console.log("remove => ",itemID)
      this.selectedRow.forEach((element,index)=>{
          if(element.id == itemID ) {
            this.selectedRow.splice(this.selectedRow.indexOf(element), 1);
          }
      })
      console.log("after remove=> ", this.selectedRow)
      this.selectedItems.forEach((element) => {
        if (element == itemID) {
          this.selectedItems.splice(this.selectedItems.indexOf(element), 1);
          console.log(this.selectedItems);
        }
      });
    }
  }

  checkAllFunction() {
    console.log("checkAll function");
    let check_all_ref = $("#defaultCheck_all").attr("check");
    if (check_all_ref == "false") {
      $("#defaultCheck_all").attr("check", "true");
      this.table_data.basic_data.forEach((element) => {
        element.forEach((element1) => {
          if (element1.settings.title == "id") {
            this.selectedItems.push(element1.value);
          }
        });
      });
      console.log("sellll => ", this.selectedItems);
    } else {
      $("#defaultCheck_all").attr("check", "false");
      this.selectedItems = [];
    }
  }

  downloadFile(byteArray, name) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(byteArray);
    var fileName = name;
    link.download = fileName;
    link.click();
  }
  bulkActionfunc(item) {
    let target = item.target_type;
    if (this.selectedItems) {
      //-=============================-Incase target_type == 'api'-==================
      if (target == "api") {
        let apiURL = item.target_url;
        console.log("delete=> ", apiURL);
        let body = {
          ...{ ids: this.selectedItems },
          ...this.apiConstData.getHeaderandBody().apiBody,
          ...item.parameters
        };
        console.log(body);
        this.httpClient
          .post(`${item.target_url}`, body, {
            headers: this.apiConstData.getHeaderandBody().apiHeaders,
          })
          .subscribe(
            (res) => {
              console.log(res);
              this.router
                .navigateByUrl("/RefrshComponent", { skipLocationChange: false })
                .then(() =>
                  this.router.navigate(["/pages/layouts/layout1"], {
                    queryParams: {
                      componentsList: this.activeComponents.getActiveComponents(),
                    },
                  })
                );
            },
            (error) => {
              console.log(error);
              this.globalFunction.showToastr(
                "danger",
                "Error",
                this.configration.apiResponseMsg.danger.msg,
                this.configration.apiResponseMsg.danger.icon
              );
            }
          );
      }

      if(target == 'ajax'){
        console.log("ajax");
        let body = {
          ...this.apiConstData.getHeaderandBody().apiBody,
          ...item.parameters
        };
        var userInfo =JSON.parse(localStorage.getItem('user-info'));

        this.httpClient

          .post(`${item.target_url}`, body, {
            headers: new HttpHeaders().delete('Content-Type').set('access-token',userInfo.api_token),
              observe: 'response', responseType: 'blob'
          }).subscribe(response=>{
            console.log("ajax response => ", response);
            this.downloadFile(response.body, 'report.xls');
          },error =>{
            console.log("error->" ,error)
          })
        
      }

      if(target == 'layout') {
        console.log("actions",item)
        // let layoutLink = this.menuService.getLayoutByID(settings.target_layout_id);
        // this.router.navigate([layoutLink])
      }

    } 
    //-==========End if (if there is selected rows)
    else {
      alert("please select rows ");
    }

  }

  changeRadioValue(item,event){
    console.log("event",event.target.checked)
    console.log("event",event)
    console.log("active item => ",item.settings);
    let body = {...item.settings.parameters,...{is_active:event.target.checked}}
    this.httpClient.post(`${item.settings.api}`,body,{
      headers: this.apiConstData.getHeaderandBody().apiHeaders,
    }).subscribe(response=>{
      console.log(response)
    })
  }

  exportToExcel()
  {
    console.log("selected rows excel => ",this.selectedRow)
    const fileName = 'hagar.xlsx';

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.selectedRow);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'hagar');

		XLSX.writeFile(wb, fileName);
  }
  openLink(link)
  {
    window.open(link,'_blank')
  }
}
